* {
    box-sizing: border-box;
}

/*Heading styling*/
h1, h2, h3{
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: goldenrod;
    margin: none;
}

h1{
    font-size: 40px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color: goldenrod;
    
}

h3{
    color: goldenrod;
}

.about h1{
    background-color: goldenrod;
    border-radius: 8px;
}

h4{
    color: goldenrod;
}

#projects{
    color: #daa520;
}

.navbar h1{
    background-color: #2f4f4ff2;
    
}

/* Header */

.header_line{
    display: flex;
}

header{
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    
}

/* Body */
body{
    color: darkslategray;
    background-color: rgb(182, 210, 191);
    background-image: url("./nebula-background.jpg");
    /*background-color: rgb(59, 1, 87);*/
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100%;
}


/* Navbar */
nav{
    background-position-y: 40%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    
    flex-direction: column;
    background-color: rgba(47, 79, 79, 1);
    padding: 10px;
    border: 1.5px solid black;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 100%;
    z-index: 2;

}

nav h1{
    margin: 0.5rem;
}

.navbar .navbar_class{
    display: inline-block;
    text-align: center;
}
.navbar .navbar_class .navbutton{
    display: inline-block;
    justify-content: center;
    background-color: goldenrod;
    border-radius: 16px;
    padding-inline: 20px;
    text-decoration: none;
    box-shadow: 0 4px rgba(0, 0, 0, .16), 0 4px rgba(0, 0, 0, .23);
    margin-inline: .5rem;
    max-height: 20px;

}
.navbar .navbar_class .navbutton a{
    font-size: 12px;
    
}

.navbar .navbar_class .navbutton:hover{
    background-color: darkgoldenrod;
    cursor: pointer;
}



.navbar .navbutton a{
    text-decoration: none;
    color: black;
}

.intro{
    text-align: center;
}

.center{
    max-width: 350px;
    height: auto;
    border: 2px solid purple;
    object-fit: cover;

}

/* About Section */
.about a{
    text-decoration: underline;
    color: goldenrod;
}

.about img{
    display: flex;
    flex: 1 0 30%;
    height: 10rem;
    border-radius: 50%;
    border: solid 2px darkslategray;

}
.portrait_main{
    width: 5%;
    height: 5%;
}

.about{
    padding-top: 10px;
    flex: 0 1 30%;
    padding-left: 100px;
    padding-right: 100px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    
    

}
.about .about_image{
    flex: 1 1 1%;
    padding-right: 1em;
}

.about .about_text{
    border: 1.5pt solid black;
    margin-left: 120px;
    padding-inline: 10px;
    width: 600px;
    border-radius: 8px;
    color: white;
    background-color: darkslategray;
    flex: 1 1 1%;
    padding-left: 1em;
}

.project_cards{
    display: flex;
    justify-content:space-around;
    flex-wrap: wrap;
}

.project_card{
    flex: 0 0 300px;
    height: 70%;
    border-radius: 16px;
    border: 1.5pt solid black;
    background-color: darkslategray;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: 10px;
    padding: .5em;
    margin: .5em;
    
}

.card_button{
    flex: 0 0 250px;
    border-radius: 16px;
    border: 1.5pt solid black;
    background-color: darkslategray;
    color: white;
    font-size: 10pt;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    max-width: 300px;
    padding-inline: 10px;
    padding: .5em;
    margin: .5em;
}

.card_button:hover{
    background-color: green;
    border: 1.5pt solid darkgoldenrod;
    transition: all 0.1s ease-in-out;
}

.cardImage{
    max-width: 250px;
    max-height: 217px;
    width: 250px;
    height: 217px;
    border-radius: 16px;
}

footer{
    color: goldenrod;
    width:100%;
    bottom:0px;
}

#projectCardContainer{
    display:flex;
}

.ArrowLeft{
    width: 100px;
    height: 30px;
    background-color: darkslategray;
    border: solid 3px goldenrod;
    margin-top: 100px;
    margin-left:  200px;
    color: goldenrod;
    cursor: pointer;

}
.ArrowRight{
    width: 100px;
    height: 30px;
    background-color: darkslategray;
    border: solid 3px goldenrod;
    margin-top: 100px;
    margin-right: 200px;
    color: goldenrod;
    cursor:pointer;

}
#leftArrowDesktop{
    width: 70px;
    height: 70px;
    margin-top: 150px;
    margin-left: 100px;
    cursor: pointer;
    
}
#leftArrowDesktop:hover{
    color: green;
    border: solid 1px darkgoldenrod;
    border-radius: 9.5px;
}
#rightArrowDesktop{
    width: 70px;
    height: 70px;
    margin-top: 150px;
    margin-right: 100px;
    cursor: pointer;
}
#rightArrowDesktop:hover{
    color: green;
    border: solid 1pt darkgoldenrod;
    border-radius: 9.5px;
}



#leftArrowMobile{
    width: 70px;
    height: 70px;
    margin-top: 50%;
    margin-left: 100px;
    cursor: pointer;
    display: none;
}
#leftArrowMobile:hover{
    color: green;
    border: solid 1px darkgoldenrod;
    border-radius: 9.5px;
}
#rightArrowMobile{
    width: 70px;
    height: 70px;
    margin-top: 50%;
    margin-right: 100px;
    cursor: pointer;
    display: none;
}
#rightArrowMobile:hover{
    color: green;
    border: solid 1pt darkgoldenrod;
    border-radius: 9.5px;
}
#portfolio_img{
    max-width: 600;
    max-height: 390px;
    width: 600px;
    height: 390px;
    border-radius: 16px;
}

.center_image{
    align-items: center;
    align-content:center;
    justify-content: center;
    
}

@media(max-width: 808px){
    .card_button h3 {
        font-size: 11px;
    }
    .card_button p{
        font-size: 6px;
    }
    .cardImage{
        display: none;
    }
    .project_card{
        width: 70%;
        height: 10%;
        
    }
    h1{
        font-size: 14px;
    }
    .about{
        display: inline-flex;
        flex-direction: column;
    }
    .project_cards{
        flex-direction: column;
        align-items: center;
        
        
    }
    .mobileButtons{
        display: block;
    }
    #leftArrowMobile{
        float: left;
        display:inline-table;
        left:0px;
    }
    #rightArrowMobile{
        display: inline-table;
        float: right;
        right: 0px;
    }
    #leftArrowDesktop{
        display: none;
        
    }
    #rightArrowDesktop{
        display: none;
        
    }
    .toolkit{
        margin-inline: 30px;
        margin-bottom: 40px;
    }
    .about{
        padding-top: 5rem;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .about_text{
        max-width: 300px !important;
        margin: 0 auto;
        float: left;


    }
    #projects_container{
        top: 30px;
    }
}

.toolkit{
    margin-inline: 60px;
    margin-bottom: 50px;
}

.toolkit_container{
    display: flex;
    flex-wrap: wrap;
}

.aboutButtons{
    justify-content: center;
    display: flex;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.linkHeaderCouple{
    display: flex;
    justify-content: space-around;
}
.navLink{
    padding-top: 10px;
    float: left;
}
.navHeader{
    padding-right: 60px;
}
.toolbox_text{
    border: 1.5pt solid black;
    padding-inline: 10px;
    width: 600px;
    border-radius: 8px;
    color: white;
    background-color: darkslategray;
}
.toolbox_text_container{
    justify-content: center;
    display: flex;
    padding-block: 15px;
    

}
@media screen and (max-width: 600px) {
    .about{
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0%;

    }
    .about .about_text{
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1.5pt solid black;
        
        width: 600px;
        border-radius: 8px;
        color: white;
        background-color: darkslategray;
    }
    .about img{
        display: flex;
        flex: 1 0 30%;
        height: 10rem;
        border-radius: 50%;
        border: solid 2px darkslategray;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    }
    .aboutButtons{
        justify-content: center;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        size: 50%;
    }
    #leftArrowDesktop{
        display: none;
        
    }
  }